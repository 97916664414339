@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');

/* Total scrollbar */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* Height of the scrollbar for horizontal scrolling */
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: #f2f0f0; /* Color of the track */
  border-radius: 10px; /* Rounded corners */
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #3174F1; /* Color of the draggable part */
  border-radius: 10px; /* Rounded corners */
}

/* Scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb when hovered */
}

/* Scrollbar corner (where vertical and horizontal scrollbars meet) */
::-webkit-scrollbar-corner {
  background: #f1f1f1; /* Color of the scrollbar corner */
}

.fontAlt{
  font-family: "Kumbh Sans", sans-serif;
}
body{
  font-family: "Outfit", sans-serif;
}
.fontCode{
  font-family: "Source Code Pro", monospace;
}
.header-container { 
  /* backdrop-filter: blur(8px); */
  background-color: rgba(255, 255, 255, 0.7); 
  backdrop-filter: blur(6px);
}
/* ripple-greenEffect.css */

.ripple-green {
  position: relative;
  overflow: hidden;
}

.ripple-green::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background: rgba(3, 246, 16, 0.1);
  opacity: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.4s, opacity 0.4s;
}

.ripple-green:hover::after {
  transform: translate(-50%, -50%) scale(10);
  opacity: 1;
}

/* ripple-blue.css */

.ripple-blue {
  position: relative;
  overflow: hidden;
}

.ripple-blue::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background: rgba(10, 78, 236, 0.1);
  opacity: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.4s, opacity 0.4s;
}

.ripple-blue:hover::after {
  transform: translate(-50%, -50%) scale(10);
  opacity: 1;
}

/* ripple-blue.css */

.ripple-red {
  position: relative;
  overflow: hidden;
}

.ripple-red::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background: rgba(236, 10, 10, 0.1);
  opacity: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.4s, opacity 0.4s;
}

.ripple-red:hover::after {
  transform: translate(-50%, -50%) scale(10);
  opacity: 1;
}

/* ripple-blue.css */

.ripple-yellow {
  position: relative;
  overflow: hidden;
}

.ripple-yellow::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background: rgba(255, 242, 0, 0.1);
  opacity: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 0.4s, opacity 0.4s;
}

.ripple-yellow:hover::after {
  transform: translate(-50%, -50%) scale(10);
  opacity: 1;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.App .header {
  width: 100%;
  height: 60px;
  padding: 8px 8px 8px 16px;
  box-sizing: border-box;
  background: #00a5e4;
  font-size: 1.2em;
  line-height: 44px;
  color: white;
}
.transf1 {
  transform: rotate(45deg);
}
.transf2 {
  transform: rotate(-45deg);
}
.sideImg{
  background-image: url('./assets/imgs/22.png');
}


.firstCell{
  width: 30%;
}
.timeCell{
  width: 10%;
}
.lastCell{
  width: 20%;
}

.cellOne{
  width: 350px;
}
.cellTwo{
  width: 150px;
}
.cellThree{
  width: 200px;
}
.cellLast{
  width: 200px;
}

.cl-internal-16vtwdp{
  display: none;
}
.cl-internal-1dauvpw{
  display: none;
}
/* In your global CSS file or Tailwind CSS file */
