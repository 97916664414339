@tailwind base;
@tailwind components;
@tailwind utilities;
body{
  font-family: "Outfit", sans-serif;
}
.webviewer {
  flex: 1;
  margin: 8px;
  -webkit-box-shadow: 1px 1px 10px #999;
  box-shadow: 1px 1px 10px #999;
  box-sizing: border-box;
  height: calc(100% - 76px);
}
.cl-internal-1dauvpw{
  display: none;
}
.cl-internal-1hp5nqm{
  display: none;
}
.secFont {
  font-family: "Quicksand", sans-serif;
}
.cl-internal-1gaxbcg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 99vh;
  background: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(10px);
  color: white;
}