/* Add these styles to your CSS file */
.loader-bar {
    width: 100%;
    height: 4px;
    background-color: #e0e0e0;
    border-radius: 2px;
    position: relative;
}

.loader-bar .progress {
    height: 100%;
    background-color: #3b82f6;
    width: 0;
    transition: width 0.4s ease;
}

.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.75);
}
